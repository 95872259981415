import {
  faAlarmExclamation,
  faBars,
  faBell,
  faBolt,
  faCalendarAlt,
  faCube,
  faDashboard,
  faDatabase,
  faFlask,
  faHome,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuthenticationFlow from 'root/hooks/useAuthenticationFlow'
import IWDivider from 'shared/components/thunderbolt/IWDivider'
import IWUserTile from 'shared/components/thunderbolt/IWUserTile'
import IWVerticalNavItem from 'shared/components/thunderbolt/IWVerticalNavItem'
import IWVerticalNavSection, {
  StyledNavigation,
} from 'shared/components/thunderbolt/IWVerticalNavSection'
import LayoutContext from 'shared/contexts/LayoutContext'
import UserContext from 'shared/contexts/UserContext'
import { getUnreadNotifications } from 'shared/userServiceClient'
import styled, { ThemeContext } from 'styled-components'

const { PUBLIC_URL, REACT_APP_IMPLY_HOST } = process.env

const defaultLogoUrl = `${PUBLIC_URL}/images/logo.svg`

export const IWNavSectionDivider = styled(IWDivider)`
  margin: 1rem 0;
`

const IWNavSectionFooterDivider = styled(IWDivider)`
  margin: 0 0.5rem;
`

const StyledNav = styled('aside')`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.others.sidebarBg};
  overflow-x: hidden;
`

const StyledLogoDiv = styled('header')<{ isMenuOpen?: boolean }>`
  justify-content: ${(props) => (props.isMenuOpen ? 'flex-start' : 'center')};
  background-color: ${(props) => props.theme.palette.others.sidebarHeaderBg};
  display: flex;
  padding: 0.75rem 1rem;
  align-items: center;
  min-height: 4rem;
  gap: 1rem;
`

const IconWrapper = styled('button')`
  border: none;
  padding: 0.25rem;
  background: transparent;
  cursor: pointer;
  align-self: flex-end;
  font-size: 1rem;
  color: ${(props) => props.theme.palette.grey[900]};
  margin: 0 0 0.25rem 0;

  & :hover {
    color: ${(props) => darken(0.1, props.theme.palette.grey[900])};
  }
`

const StyledFooter = styled('footer')`
  padding: 1.875rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const IWVerticalNavSubMenuItemWrapper = styled.div`
  margin-left: 1.5rem;
`

const NotificationsButton = () => {
  const { t } = useTranslation()
  const { isNotificationListMenuOpen, setIsNotificationListMenuOpen } =
    useContext(LayoutContext)

  const { data } = useQuery('unreadNotificationCount', getUnreadNotifications)
  return (
    <IWVerticalNavItem
      label={t('verticalNavigation.labels.notifications')}
      badgeValue={data}
      icon={faBell}
      onClick={() => {
        setIsNotificationListMenuOpen(!isNotificationListMenuOpen)
      }}
    />
  )
}

/**
 * This is Innowatts' Vertical Navigation Menu,
 * it takes VerticalNavSection components as its children
 */
const IWVerticalNavigation = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { logout: onLogout } = useAuthenticationFlow(navigate)
  const user = useContext(UserContext)
  const { availableTools, availableToolPolicies } = user
  const { toggleMenu, menuOpen } = useContext(LayoutContext)
  const [showLSToolsSubmenu, setShowLSToolsSubmenu] = useState<boolean>(false)
  const {
    palette: { logoUrl = defaultLogoUrl },
  } = useContext(ThemeContext)

  return (
    <StyledNav data-testid="vertical-nav">
      <StyledLogoDiv isMenuOpen={menuOpen}>
        <div style={{}}>
          <IconWrapper
            onClick={toggleMenu}
            data-testid="vertical-nav-hamburger"
          >
            <FontAwesomeIcon icon={faBars} />
          </IconWrapper>
        </div>
        {menuOpen && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              maxHeight: '48px',
            }}
          >
            <img
              style={{
                display: 'flex',
                flex: 1,
                width: '100%',
              }}
              data-testid="vertical-nav-logo"
              src={logoUrl}
              alt="Logo"
            />
          </div>
        )}
      </StyledLogoDiv>
      <StyledNavigation collapsed={!menuOpen}>
        <IWVerticalNavSection>
          <IWVerticalNavItem
            as="routerNavLink"
            collapsed={!menuOpen}
            label={t('verticalNavigation.labels.overview')}
            icon={faHome}
            active={pathname === '/overview'}
            to="/overview"
          />
        </IWVerticalNavSection>
        {menuOpen && <IWNavSectionDivider />}
        <IWVerticalNavSection
          title={menuOpen ? t('verticalNavigation.labels.toolkit') : ''}
        >
          {availableTools.includes('insightsManager') && (
            <IWVerticalNavItem
              as="routerNavLink"
              collapsed={!menuOpen}
              label={t('verticalNavigation.labels.insightManager')}
              icon={faCube}
              active={pathname === '/tools/insights-manager'}
              to="/tools/insights-manager"
            />
          )}
          {availableTools.includes('dashboardsManager') && (
            <IWVerticalNavItem
              as="routerNavLink"
              collapsed={!menuOpen}
              label={t('verticalNavigation.labels.dashboardsManager')}
              icon={faDashboard}
              active={pathname === '/tools/dashboards-manager'}
              to="/tools/dashboards-manager"
            />
          )}
          {availableTools.includes('alertsManager') && (
            <IWVerticalNavItem
              as="routerNavLink"
              collapsed={!menuOpen}
              label={t('verticalNavigation.labels.alertsManager')}
              icon={faAlarmExclamation}
              active={pathname === '/tools/alerts-manager'}
              to="/tools/alerts-manager"
            />
          )}
          {availableTools.includes('dataExploration') && (
            <IWVerticalNavItem
              as="a"
              href={REACT_APP_IMPLY_HOST || '/imply'}
              target="_blank"
              collapsed={!menuOpen}
              label={t('verticalNavigation.labels.dataExploration')}
              icon={faFlask}
            />
          )}
          {availableTools.includes('loadScheduling') && (
            <>
              <IWVerticalNavItem
                drawer
                expanded={showLSToolsSubmenu}
                collapsed={!menuOpen}
                label={t('verticalNavigation.labels.loadScheduling')}
                icon={faCalendarAlt}
                onClick={() => setShowLSToolsSubmenu(!showLSToolsSubmenu)}
              />
              {showLSToolsSubmenu && menuOpen && (
                <IWVerticalNavSubMenuItemWrapper>
                  <IWVerticalNavItem
                    as="routerNavLink"
                    collapsed={!menuOpen}
                    label={t(
                      'verticalNavigation.labels.loadSchedulingDemandBidsScheduling',
                    )}
                    active={pathname === '/tools/load-scheduling/submit'}
                    to="/tools/load-scheduling/submit"
                  />
                  {availableToolPolicies.includes('loadScheduling:ibt') && (
                    <IWVerticalNavItem
                      as="routerNavLink"
                      collapsed={!menuOpen}
                      label={t('verticalNavigation.labels.loadSchedulingIBT')}
                      active={pathname === '/tools/load-scheduling/ibt'}
                      to="/tools/load-scheduling/ibt"
                    />
                  )}
                  <IWVerticalNavItem
                    as="routerNavLink"
                    collapsed={!menuOpen}
                    label={t(
                      'verticalNavigation.labels.loadSchedulingSubmissionHistory',
                    )}
                    active={
                      pathname === '/tools/load-scheduling/submission-history'
                    }
                    to="/tools/load-scheduling/submission-history"
                  />
                </IWVerticalNavSubMenuItemWrapper>
              )}
            </>
          )}

          {availableTools.includes('importManager') && (
            <IWVerticalNavItem
              as="routerNavLink"
              collapsed={!menuOpen}
              label={t('verticalNavigation.labels.importManager')}
              icon={faDatabase}
              active={pathname === '/tools/import-manager'}
              to="/tools/import-manager"
            />
          )}
        </IWVerticalNavSection>
      </StyledNavigation>
      <IWNavSectionFooterDivider />
      {menuOpen && (
        <StyledFooter>
          <NotificationsButton />
          <IWVerticalNavSection>
            <IWVerticalNavItem
              as="routerNavLink"
              collapsed={!menuOpen}
              label={t('verticalNavigation.labels.innowattsProduct')}
              icon={faBolt}
              active={pathname === '/innowatts-products'}
              to="/innowatts-products"
            />
          </IWVerticalNavSection>
          <IWVerticalNavSection>
            <IWUserTile
              header={user.name}
              subHeader={user.email}
              onLogout={onLogout}
              isAdmin={availableToolPolicies.includes('system:admin')}
            />
          </IWVerticalNavSection>
        </StyledFooter>
      )}
    </StyledNav>
  )
}

export default IWVerticalNavigation
