import { DefaultTheme } from 'styled-components'

export type OrgThemeConfig = DefaultTheme['palette']

const { PUBLIC_URL } = process.env

export const lightTheme: OrgThemeConfig = {
  logoUrl: `${PUBLIC_URL}/images/logo.svg`,
  themeType: 'light',
  others: {
    sidebarHeaderBg: '#0072ce',
    sidebarBg: '#b9dcf7',
  },
  primary: {
    50: '#e2f1fb',
    100: '#b9dcf7',
    200: '#8dc7f3',
    300: '#5fb1ee',
    400: '#3aa1ec',
    500: '#0892e8',
    600: '#0084db',
    700: '#0072ce',
    800: '#0062b7',
    900: '#004498',
  },
  grey: {
    0: '#ffffff',
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
  },
  secondary: {
    50: '#f1f7e4',
    100: '#dcebbd',
    200: '#c4dd92',
    300: '#abd064',
    400: '#97c73e',
    500: '#84bd00',
    600: '#74ad00',
    700: '#5f9a00',
    800: '#498700',
    900: '#1b6500',
  },
  warning: {
    50: '#fff8e1',
    100: '#ffecb3',
    200: '#ffe082',
    300: '#ffd54f',
    400: '#ffca28',
    500: '#ffc107',
    600: '#ffb300',
    700: '#ffa000',
    800: '#ff8f00',
    900: '#ff6f00',
  },
  alert: {
    50: '#ffe9ec',
    100: '#ffc7cc',
    200: '#f98f8d',
    300: '#f16161',
    400: '#fb3237',
    500: '#ff000f',
    600: '#f10013',
    700: '#df000e',
    800: '#d20004',
    900: '#c50000',
  },
}

export const darkTheme: OrgThemeConfig = {
  logoUrl: `${PUBLIC_URL}/images/logo.svg`,
  themeType: 'dark',
  others: {
    sidebarHeaderBg: '#8dc7f3',
    sidebarBg: '#111827',
  },
  grey: {
    0: '#000000',
    50: '#111827',
    100: '#1f2937',
    200: '#374151',
    300: '#4b5563',
    400: '#6b7280',
    500: '#9ca3af',
    600: '#d1d5db',
    700: '#e5e7eb',
    800: '#f3f4f6',
    900: '#f9fafb',
  },
  primary: {
    50: '#004498',
    100: '#0062b7',
    200: '#0072ce',
    300: '#0084db',
    400: '#0892e8',
    500: '#3aa1ec',
    600: '#5fb1ee',
    700: '#8dc7f3',
    800: '#b9dcf7',
    900: '#e2f1fb',
  },
  secondary: {
    50: '#1b6500',
    100: '#498700',
    200: '#5f9a00',
    300: '#74ad00',
    400: '#84bd00',
    500: '#97c73e',
    600: '#abd064',
    700: '#c4dd92',
    800: '#dcebbd',
    900: '#f1f7e4',
  },
  alert: {
    50: '#c50000',
    100: '#d20004',
    200: '#df000e',
    300: '#f10013',
    400: '#ff000f',
    500: '#fb3237',
    600: '#f16161',
    700: '#f98f8d',
    800: '#ffc7cc',
    900: '#ffe9ec',
  },
  warning: {
    50: '#ff6f00',
    100: '#ff8f00',
    200: '#ffa000',
    300: '#ffb300',
    400: '#ffc107',
    500: '#ffca28',
    600: '#ffd54f',
    700: '#ffe082',
    800: '#ffecb3',
    900: '#fff8e1',
  },
}

export const generalTheme = (theme: OrgThemeConfig): DefaultTheme => {
  return {
    palette: theme as OrgThemeConfig,
    layers: {
      generallayout: '1',
      overlayout: '2',
      dropdown: '1000',
      sticky: '1020',
      fixed: '1030',
      backdrop: '1040',
      offcanvas: '1050',
      modal: '1060',
      popover: '1070',
      tooltip: '1080',
    },
    typography: {
      fontSizes: {
        xxxl: '2rem',
        xxl: '1.5rem',
        xl: '1.5rem',
        lg: '1.25rem',
        base: '1rem',
        sm: '0.875rem',
        xs: '0.75rem',
      },
      lineHeights: {
        xxl: '2rem',
        xl: '1.75',
        lg: '1.5rem',
        base: '1.5rem',
        sm: '1.25rem',
        xs: '1rem',
      },
      fontWeights: {
        regular: 400,
        medium: 500,
        bold: 700,
      },
    },
    dimensions: {
      maxScreenWidth: '1400px',
    },
  }
}
